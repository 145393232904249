import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

class WorkdaysList extends React.Component {
  atLeastOneWorkdayIsSelected = () => {
    const { workdaysSelected } = this.props;
    if (!workdaysSelected) return false;
    let oneSelected = false;
    Object.keys(workdaysSelected).forEach(workDayId => {
      if (workdaysSelected[workDayId]) oneSelected = true;
    })
    return oneSelected;
  }

  render() {
    const { workdaysSelected } = this.props;
    const workdaysDisplayed = workdaysSelected || {
      MON: false,
      TUE: false,
      WED: false,
      THU: false,
      FRI: false,
      SAT: false,
      SUN: false,
    };

    return (
      <React.Fragment>
        <div className="workdayListWrapper">
          {this.atLeastOneWorkdayIsSelected() ? Object.keys(workdaysDisplayed).map(workday => (
            workdaysDisplayed[workday] ? (<Label key={workday} basic size="mini" className="workdayListItem">{workday}</Label>) : null
          )) : <Label color="orange" image size="mini" className="workdayListItem"><Icon name='exclamation triangle' /><Label.Detail>No workdays selected</Label.Detail></Label>}
        </div>
      </React.Fragment>
    );
  }
}

export default WorkdaysList;