import EmployeesActionTypes from './employees.types';

// Add employee

export const addEmployeeStart = ({ currentCompany, newEmployeeData }) => ({
  type: EmployeesActionTypes.ADD_EMPLOYEE_START,
  payload: {
    currentCompany,
    newEmployeeData
  }
});

export const addEmployeeSuccess = ({ newEmployeeId, newEmployeeData }) => ({
  type: EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS,
  payload: {
    newEmployeeId,
    newEmployeeData
  }
});

export const addEmployeeFailure = error => ({
  type: EmployeesActionTypes.ADD_EMPLOYEE_FAILURE,
  payload: error
});

// Get employees

export const getEmployeesStart = ({ currentCompany }) => ({
  type: EmployeesActionTypes.GET_EMPLOYEES_START,
  payload: {
    currentCompany
  }
});

export const getEmployeesSuccess = (currentEmployees) => ({
  type: EmployeesActionTypes.GET_EMPLOYEES_SUCCESS,
  payload: {
    currentEmployees
  }
});

export const getEmployeesFailure = error => ({
  type: EmployeesActionTypes.GET_EMPLOYEES_FAILURE,
  payload: error
});

// Update employee

export const updateEmployeeStart = ({ employeeId, employeeData, currentCompany }) => ({
  type: EmployeesActionTypes.UPDATE_EMPLOYEE_START,
  payload: {
    employeeId,
    employeeData,
    currentCompany
  }
});

export const updateEmployeeSuccess = ({ employeeId, employeeData }) => ({
  type: EmployeesActionTypes.UPDATE_EMPLOYEE_SUCCESS,
  payload: {
    employeeId,
    employeeData
  }
});

export const updateEmployeeFailure = error => ({
  type: EmployeesActionTypes.UPDATE_EMPLOYEE_FAILURE,
  payload: error
});

// Delete employee

export const deleteEmployeeStart = ({ currentCompany, currentEmployees, employeeId }) => ({
  type: EmployeesActionTypes.DELETE_EMPLOYEE_START,
  payload: {
    currentCompany,
    currentEmployees,
    employeeId
  }
});

export const deleteEmployeeSuccess = ({ employeeData, removedId }) => ({
  type: EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS,
  payload: {
    employeeData,
    removedId
  }
});

export const deleteEmployeeFailure = error => ({
  type: EmployeesActionTypes.DELETE_EMPLOYEE_FAILURE,
  payload: error
});