const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',

  RESET_USER_PASSWORD_START: 'RESET_USER_PASSWORD_START',
  RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
  RESET_USER_PASSWORD_FAILURE: 'RESET_USER_PASSWORD_FAILURE',

  GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',

  EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',

  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

  CREATE_INITIAL_USERPROFILE_START: 'CREATE_INITIAL_USERPROFILE_START',
  CREATE_INITIAL_USERPROFILE_SUCCESS: 'CREATE_INITIAL_USERPROFILE_SUCCESS',
  CREATE_INITIAL_USERPROFILE_FAILURE: 'CREATE_INITIAL_USERPROFILE_FAILURE',

  CREATE_INITIAL_COMPANY_START: 'CREATE_INITIAL_COMPANY_START',
  CREATE_INITIAL_COMPANY_SUCCESS: 'CREATE_INITIAL_COMPANY_SUCCESS',
  CREATE_INITIAL_COMPANY_FAILURE: 'CREATE_INITIAL_COMPANY_FAILURE',

  UNLOCK_ACCESS_START: 'UNLOCK_ACCESS_START',
  UNLOCK_ACCESS_SUCCESS: 'UNLOCK_ACCESS_SUCCESS',
  UNLOCK_ACCESS_FAILURE: 'UNLOCK_ACCESS_FAILURE',
};

export default UserActionTypes;