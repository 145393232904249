import { createSelector } from 'reselect';

const selectOnboarding = state => state.onboarding;

export const selectOnboardingErrors = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.error
);

export const selectOnboardingStepNum = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.onboardingStepNum
);

export const selectIsOnboardingDocLoading = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.loadingOnboardingDoc
);

export const selectOnboardingCategoryValues = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.categoryValues
);

export const selectIsAddGoalModalOpen = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.isAddGoalModalOpen
);

export const selectGoalToEdit = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.goalToEdit
);

export const selectIsAddGoalLoading = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.loadingAddGoal
);

export const selectIsLoadingOnboardingAttributes = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.loadingOnboardingAttributes
);

export const selectCompanyProfile = createSelector(
  [selectOnboarding],
  (onboarding) => onboarding.companyProfile
);

