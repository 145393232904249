const ShiftsActionTypes = {
  ADD_SHIFT_START: 'ADD_SHIFT_START',
  ADD_SHIFT_SUCCESS: 'ADD_SHIFT_SUCCESS',
  ADD_SHIFT_FAILURE: 'ADD_SHIFT_FAILURE',

  GET_SHIFTS_START: 'GET_SHIFTS_START',
  GET_SHIFTS_SUCCESS: 'GET_SHIFTS_SUCCESS',
  GET_SHIFTS_FAILURE: 'GET_SHIFTS_FAILURE',

  UPDATE_SHIFT_START: 'UPDATE_SHIFT_START',
  UPDATE_SHIFT_SUCCESS: 'UPDATE_SHIFT_SUCCESS',
  UPDATE_SHIFT_FAILURE: 'UPDATE_SHIFT_FAILURE',

  DELETE_SHIFT_START: 'DELETE_SHIFT_START',
  DELETE_SHIFT_SUCCESS: 'DELETE_SHIFT_SUCCESS',
  DELETE_SHIFT_FAILURE: 'DELETE_SHIFT_FAILURE',

  UPDATE_EMPLOYEE_SHIFT_START: 'UPDATE_EMPLOYEE_SHIFT_START',
  UPDATE_EMPLOYEE_SHIFT_SUCCESS: 'UPDATE_EMPLOYEE_SHIFT_SUCCESS',
  UPDATE_EMPLOYEE_SHIFT_FAILURE: 'UPDATE_EMPLOYEE_SHIFT_FAILURE',
};

export default ShiftsActionTypes;