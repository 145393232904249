import React from 'react';
import { Button } from 'semantic-ui-react';

class WorkdaysToggle extends React.Component {
  render() {
    const { workdaysSelected, onToggleWorkday } = this.props;

    return (
      <React.Fragment>
      <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
        <div className="weekdaySegment">
          <div className="weekdayLabel">MON</div>
          <Button type="button" circular icon={workdaysSelected["MON"] ? "check" : "plus"} color={workdaysSelected["MON"] ? "green" : null} onClick={() => onToggleWorkday('MON')}/>
        </div>
        <div className="weekdaySegment">
          <div className="weekdayLabel">TUE</div>
          <Button type="button" circular icon={workdaysSelected["TUE"] ? "check" : "plus"} color={workdaysSelected["TUE"] ? "green" : null} onClick={() => onToggleWorkday('TUE')}/>
        </div>
        <div className="weekdaySegment">
          <div className="weekdayLabel">WED</div>
          <Button type="button" circular icon={workdaysSelected["WED"] ? "check" : "plus"} color={workdaysSelected["WED"] ? "green" : null} onClick={() => onToggleWorkday('WED')}/>
        </div>
        <div className="weekdaySegment">
          <div className="weekdayLabel">THU</div>
          <Button type="button" circular icon={workdaysSelected["THU"] ? "check" : "plus"} color={workdaysSelected["THU"] ? "green" : null} onClick={() => onToggleWorkday('THU')}/>
        </div>
        <div className="weekdaySegment">
          <div className="weekdayLabel">FRI</div>
          <Button type="button" circular icon={workdaysSelected["FRI"] ? "check" : "plus"} color={workdaysSelected["FRI"] ? "green" : null} onClick={() => onToggleWorkday('FRI')}/>
        </div>
      </div>
    
      <div style={{ marginTop: '6px', display: 'flex', justifyContent: 'center' }}>
        <div className="weekdaySegment">
          <div className="weekdayLabel">SAT</div>
          <Button type="button" circular icon={workdaysSelected["SAT"] ? "check" : "plus"} color={workdaysSelected["SAT"] ? "green" : null} onClick={() => onToggleWorkday('SAT')}/>
        </div>
        <div className="weekdaySegment">
          <div className="weekdayLabel">SUN</div>
          <Button type="button" circular icon={workdaysSelected["SUN"] ? "check" : "plus"} color={workdaysSelected["SUN"] ? "green" : null} onClick={() => onToggleWorkday('SUN')}/>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default WorkdaysToggle;