import ShiftsActionTypes from './shifts.types';

// Add shift

export const addShiftStart = ({ currentCompany, newShiftData }) => ({
  type: ShiftsActionTypes.ADD_SHIFT_START,
  payload: {
    currentCompany,
    newShiftData
  }
});

export const addShiftSuccess = ({ newShiftId, newShiftData }) => ({
  type: ShiftsActionTypes.ADD_SHIFT_SUCCESS,
  payload: {
    newShiftId,
    newShiftData
  }
});

export const addShiftFailure = error => ({
  type: ShiftsActionTypes.ADD_SHIFT_FAILURE,
  payload: error
});

// Get shifts

export const getShiftsStart = ({ currentCompany }) => ({
  type: ShiftsActionTypes.GET_SHIFTS_START,
  payload: {
    currentCompany
  }
});

export const getShiftsSuccess = (currentShifts) => ({
  type: ShiftsActionTypes.GET_SHIFTS_SUCCESS,
  payload: {
    currentShifts
  }
});

export const getShiftsFailure = error => ({
  type: ShiftsActionTypes.GET_SHIFTS_FAILURE,
  payload: error
});

// Update shift

export const updateShiftStart = ({ shiftId, shiftData, currentCompany }) => ({
  type: ShiftsActionTypes.UPDATE_SHIFT_START,
  payload: {
    shiftId,
    shiftData,
    currentCompany
  }
});

export const updateShiftSuccess = (shiftData) => ({
  type: ShiftsActionTypes.UPDATE_SHIFT_SUCCESS,
  payload: {
    shiftData
  }
});

export const updateShiftFailure = error => ({
  type: ShiftsActionTypes.UPDATE_SHIFT_FAILURE,
  payload: error
});

// Delete shift

export const deleteShiftStart = ({ currentCompany, currentShifts, shiftId }) => ({
  type: ShiftsActionTypes.DELETE_SHIFT_START,
  payload: {
    currentCompany,
    currentShifts,
    shiftId
  }
});

export const deleteShiftSuccess = ({ shiftData, removedId }) => ({
  type: ShiftsActionTypes.DELETE_SHIFT_SUCCESS,
  payload: {
    shiftData,
    removedId
  }
});

export const deleteShiftFailure = error => ({
  type: ShiftsActionTypes.DELETE_SHIFT_FAILURE,
  payload: error
});

// Update employee shift assignment

export const updateEmployeeShiftStart = ({ employeeId, shiftId }) => ({
  type: ShiftsActionTypes.UPDATE_EMPLOYEE_SHIFT_START,
  payload: {
    employeeId,
    shiftId
  }
});

export const updateEmployeeShiftSuccess = ({ employeeId, shiftId }) => ({
  type: ShiftsActionTypes.UPDATE_EMPLOYEE_SHIFT_SUCCESS,
  payload: {
    employeeId,
    shiftId
  }
});

export const updateEmployeeShiftFailure = error => ({
  type: ShiftsActionTypes.UPDATE_EMPLOYEE_SHIFT_FAILURE,
  payload: error
});
