import { takeLatest, put, all, call } from 'redux-saga/effects';

import ShiftsActionTypes from './shifts.types';

import { 
  addShiftSuccess, addShiftFailure,
  getShiftsStart, getShiftsSuccess, getShiftsFailure,
  updateShiftSuccess, updateShiftFailure,
  deleteShiftSuccess, deleteShiftFailure,
  updateEmployeeShiftSuccess, updateEmployeeShiftFailure
} from './shifts.actions';

import { setCurrentView } from '../view/view.actions';

import { getEmployeesStart } from '../employees/employees.actions';

import {
  addShiftDocument, getCurrentShiftDocuments, updateShiftDocument, deleteShiftDocument, updateEmployeeShiftAssignment
} from '../../firebase/firebase.utils';

export function* addShift({ payload: { currentCompany, newShiftData } }) {
  try {
    const newShiftId = yield addShiftDocument(currentCompany, newShiftData);
    yield put(addShiftSuccess({ newShiftId, newShiftData }));
    yield put(getEmployeesStart({ currentCompany }));
    yield put(setCurrentView("ShiftList"));
  } catch (error) {
    yield put(addShiftFailure(error));
  }
}

export function* onAddShiftStart() {
  yield takeLatest(ShiftsActionTypes.ADD_SHIFT_START, addShift);
}

export function* getCurrentShifts({ payload: { currentCompany } }) {
  try {
    const currentShifts = yield getCurrentShiftDocuments(currentCompany);
    yield put(getShiftsSuccess(currentShifts));
  } catch (error) {
    yield put(getShiftsFailure(error));
  }
}

export function* onGetCurrentShifts() {
  yield takeLatest(ShiftsActionTypes.GET_SHIFTS_START, getCurrentShifts);
}

export function* updateShift({ payload: { shiftId, shiftData, currentCompany } }) {
  try {
    yield updateShiftDocument(shiftId, shiftData);
    yield put(updateShiftSuccess(shiftData));
    yield put(getShiftsStart({ currentCompany }));
  } catch (error) {
    yield put(updateShiftFailure(error));
  }
}

export function* onUpdateShiftStart() {
  yield takeLatest(ShiftsActionTypes.UPDATE_SHIFT_START, updateShift);
}

export function* deleteShift({ payload: { currentCompany, currentShifts, shiftId } }) {
  try {
    yield deleteShiftDocument(currentCompany, shiftId);
    let newShiftData = { ...currentShifts };
    delete newShiftData[shiftId];
    yield put(deleteShiftSuccess({ shiftData: newShiftData, removedId: shiftId }));
    yield put(setCurrentView("ShiftList"));
  } catch (error) {
    yield put(deleteShiftFailure(error));
  }
}

export function* onDeleteShift() {
  yield takeLatest(ShiftsActionTypes.DELETE_SHIFT_START, deleteShift);
}

export function* updateEmployeeShift({ payload: { employeeId, shiftId } }) {
  try {
    yield updateEmployeeShiftAssignment(employeeId, shiftId);
    yield put(updateEmployeeShiftSuccess({ employeeId, shiftId: shiftId || "Unassigned" }));
  } catch (error) {
    yield put(updateEmployeeShiftFailure(error));
  }
}

export function* onUpdateEmployeeShift() {
  yield takeLatest(ShiftsActionTypes.UPDATE_EMPLOYEE_SHIFT_START, updateEmployeeShift);
}

export function* shiftsSagas() {
  yield all([
    call(onAddShiftStart),
    call(onGetCurrentShifts),
    call(onUpdateShiftStart),
    call(onDeleteShift),
    call(onUpdateEmployeeShift)
  ]);
}