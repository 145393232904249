import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Icon,
  Image,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Dropdown
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './AppContainer.styles.css';

// Create links
const MENU_LINKS = [
  // { name: "Home", icon: "home", active: true },
  // { name: "Our Mission" },
  // { name: "Cases" },
  // { name: "Debates" },
  // { name: "Press" }
];

// Render menu links
const renderMenuLinks = ({ renderIcons }) => (
  MENU_LINKS.map((menu_link, menu_link_i) => (
    <Menu.Item as='a' active={!!menu_link.active} key={menu_link_i}>
      {menu_link.name}
      {menu_link.icon && renderIcons ? <Icon name={menu_link.icon} /> : null}
    </Menu.Item>
  ))
);

// Create sidebar options
const UserDropdownActions = ({ currentUser, signOutStart }) => (
  <Dropdown item text={currentUser.displayName || currentUser.email}>
    <Dropdown.Menu>
      <Dropdown.Header>Currently Logged In</Dropdown.Header>
      <Dropdown.Item onClick={() => signOutStart()}><Icon name="sign-out"/> Sign Out</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

const HomepageSidebarOptions = ({ currentUser,  signOutStart }) => (
  currentUser ? 
    <UserDropdownActions currentUser={currentUser} signOutStart={signOutStart} />
  :
  <React.Fragment>
    <Menu.Item>
      <Button as={Link} fluid color={BrandColors.SEMANTIC_COLOR_PRIMARY} to="/signup">
        Sign Up
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button as={Link} fluid to="/login" style={{ marginBottom: '0.25em' }}>
        Log In
      </Button>
    </Menu.Item>
  </React.Fragment>
);

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends React.Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: false })

  render() {
    const { children, currentUser, signOutStart } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            textAlign='center'
            style={{ padding: '0em 0em 0em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              // inverted={!fixed}
              // pointing={!fixed}
              // secondary={!fixed}
              size='large'
              className="boxMenu"
            >
              <Container>

                <Menu.Item as={Link} to="/" style={{ padding: 0, border: 'none' }}>
                  <Image src={fixed ? "/logos/Salus_Logo_wide_b.png" : "/logos/Salus_Logo_wide_b.png"} className="navLogo" />
                  {/* <div className="logoNavText">SALUS</div> */}
                </Menu.Item>

                {renderMenuLinks({ renderIcons: false })}

                <Menu.Menu position='right'>

                  { currentUser ? 
                    <UserDropdownActions currentUser={currentUser} signOutStart={signOutStart} />
                    :
                    <React.Fragment>
                      <Menu.Item as={Link} to="/login">
                        Login
                      </Menu.Item>
                      <Menu.Item>
                        <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} style={{ marginLeft: '0.5em' }} className="round">
                          Get Started
                          <Icon name='right chevron' />
                        </Button>
                      </Menu.Item>
                    </React.Fragment>
                  }

                </Menu.Menu>

              </Container>
            </Menu>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
}

class MobileContainer extends React.Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, currentUser, signOutStart } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
        className="mobileWrapper"
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
          className="overflowingSidebar"
        >

          <Divider hidden/>

          <HomepageSidebarOptions currentUser={currentUser} signOutStart={signOutStart} />
          <Menu.Item>
            <Menu.Menu>

            {renderMenuLinks({ renderIcons: true })}

            </Menu.Menu>
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened} className="overflowingSidebar">
          <Segment
            // inverted
            textAlign='center'
            style={{ padding: '0em 0em 1em 0em', border: 'none' }}
            vertical
          >
            <Container>
              <Menu pointing size='large' className="boxMenu negSideMargins1">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>

                <Menu.Item as={Link} to="/" style={{ padding: 0, border: 'none' }}>
                  <Image src={"/logos/Salus_Logo_wide_b.png"} className="navLogo" />
                  {/* <div className="logoNavText">SALUS</div> */}
                </Menu.Item>
                
                <Menu.Menu position='right'>
                  
                { currentUser ? 
                  <UserDropdownActions currentUser={currentUser} signOutStart={signOutStart} />
                  :
                  <Menu.Item>
                    <Button as={Link} to="/login">
                      Log In
                    </Button>
                    {/* <Button as={Link} color={BrandColors.SEMANTIC_COLOR_PRIMARY} style={{ marginLeft: '0.5em' }} to="/signup">
                      Sign Up
                    </Button> */}
                  </Menu.Item>
                }
                </Menu.Menu>

              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children, ...props }) => (
  <div>
    <DesktopContainer {...props}>{children}</DesktopContainer>
    <MobileContainer {...props}>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const AppContainer = ({children, ...props}) => (
  <ResponsiveContainer {...props}>
    {children}
  </ResponsiveContainer>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);