import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Message, Divider, Segment, Header, Button, Form, Breadcrumb } from 'semantic-ui-react';

import BrandColors from '../../utils/brandColors';
import { cloudFunctions } from '../../firebase/cloudFunctions';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectOnboardingStepNum, selectIsLoadingOnboardingAttributes, selectOnboardingErrors, selectCompanyProfile } from '../../redux/onboarding/onboarding.selectors';

import { signOutStart } from '../../redux/user/user.actions';
import { backStepStart, updateOnboardingAttributesStart, updateOnboardingAttributesFailure } from '../../redux/onboarding/onboarding.actions';

import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

const WORKFORCE_SIZE_OPTIONS = [
  { key: '1', text: '0 - 50 employees', value: "50 or less" },
  { key: '2', text: '51 - 250 employees', value: "51 - 250" },
  { key: '3', text: '250 - 1000 employees', value: "250 - 1000" },
  { key: '4', text: 'More than 1000 employees', value: "1000+" },
];

class OnboardingSurvey extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    companyName: '', 
    phoneNumber: '', 
    companyCity: '', 
    companyState: '', 
    workforceSize: '', 
    anyQuestions: ''
  }

  componentDidMount() {
    const { companyProfile } = this.props;
    if (companyProfile) {
      console.log("Previous company profile found.");
      this.setState({
        ...companyProfile
      });
    }
  }

  validateCompanyProfile = (companyProfile) => {
    // if (!companyProfile.companyName) return false
    return true;
  }

  handleSubmit = async event => {
    // event.preventDefault();
    const { currentUser, updateOnboardingAttributesStart, updateOnboardingAttributesFailure } = this.props;
    const { firstName, lastName, companyName, phoneNumber, companyCity, companyState, workforceSize, anyQuestions } = this.state;

    // Keep track of last updated moment
    const updatedAt = new Date();

    const newAttributes = {
      companyProfile: {
        updatedAt,
        firstName,
        lastName,
        companyName,
        phoneNumber,
        companyCity,
        companyState,
        workforceSize,
        anyQuestions
      }
    }

    if (!this.validateCompanyProfile(newAttributes.companyProfile)) {
      updateOnboardingAttributesFailure({
        code: "custom/failed-update-onboarding-company",
        message: "Please make sure you've filled out the form above properly. If you're still having issues, please contact support@SalusSMS.com"
      });
      return;
    }

    // TBD - Combine below into a single action

    // Trigger notification email
    cloudFunctions.post('notifyCompanyProfileSubmission', {
      currentUser,
      companyProfile: newAttributes.companyProfile
    }).then(res => {
      console.log("Response from notifyCompanyProfileSubmission: ",res);
    });

    // Trigger redux action
    updateOnboardingAttributesStart({ currentUser, newAttributes });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { backStepStart, currentUser, isLoadingOnboardingAttributes, onBoardingErrors } = this.props;
    const { firstName, lastName, companyName, phoneNumber, companyCity, companyState, workforceSize, anyQuestions } = this.state;

    return (
      <Container>
        <Divider hidden />
        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => backStepStart(currentUser)}>Go Back</Breadcrumb.Section>
        </Breadcrumb>
        <Divider hidden />
        <Segment.Group stacked>
          <Segment>
            <Header as="h1">Complete your profile</Header>
          </Segment>
          <Segment>
            <Form size='large' onSubmit={this.handleSubmit} loading={!!isLoadingOnboardingAttributes}>
              <p style={{fontWeight: 'bold'}}>Please complete your profile below to get <span style={{color: BrandColors.HEX_COLOR_PRIMARY}}>early access</span> to SalusSMS.</p>
              <Form.Group widths="equal">
                <Form.Input 
                  fluid 
                  required
                  icon='user'
                  iconPosition='left' 
                  placeholder='First Name' 
                  name="firstName" 
                  value={firstName}
                  onChange={this.handleChange} 
                />
                <Form.Input 
                  fluid 
                  required
                  // icon='building'
                  // iconPosition='left' 
                  placeholder='Last Name' 
                  name="lastName" 
                  value={lastName}
                  onChange={this.handleChange} 
                />
              </Form.Group>
              <Form.Input 
                fluid 
                required
                icon='building'
                iconPosition='left' 
                placeholder='Company Name' 
                name="companyName" 
                value={companyName}
                onChange={this.handleChange} 
              />
              <Form.Input 
                fluid 
                icon='phone'
                iconPosition='left' 
                placeholder='Phone Number' 
                name="phoneNumber" 
                value={phoneNumber}
                onChange={this.handleChange} 
              />
              <Form.Input 
                fluid 
                icon='map marker alternate'
                iconPosition='left' 
                placeholder='City' 
                name="companyCity" 
                value={companyCity}
                onChange={this.handleChange} 
              />
              <Form.Input 
                fluid 
                icon='map marker alternate'
                iconPosition='left' 
                placeholder='State' 
                name="companyState" 
                value={companyState}
                onChange={this.handleChange} 
              />
              <Form.Select 
                fluid
                placeholder='Approximate Workforce Size' 
                name="workforceSize" 
                options={WORKFORCE_SIZE_OPTIONS}
                value={workforceSize}
                onChange={this.handleSelectChange} 
              />
              <Form.TextArea 
                placeholder="Any questions?" 
                name="anyQuestions" 
                value={anyQuestions}
                onChange={this.handleChange} 
              />

            </Form>
          </Segment>
          <Segment secondary>
            <Button 
              color="green" 
              content={"Complete Profile"} 
              icon={"right chevron"}
              disabled={!!isLoadingOnboardingAttributes}
              loading={!!isLoadingOnboardingAttributes}
              labelPosition="right" 
              onClick={() => this.handleSubmit()}
            />
          </Segment>
        </Segment.Group>

        {
          onBoardingErrors ? (
            <Message error>
              <Message.Header>{onBoardingErrors.title}</Message.Header>
              { renderCustomizedErrorMessage(onBoardingErrors.error.code, onBoardingErrors.error.message) }
            </Message>
          ) : null
        }
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  stepNum: selectOnboardingStepNum,
  currentUser: selectCurrentUser,
  isLoadingOnboardingAttributes: selectIsLoadingOnboardingAttributes,
  onBoardingErrors: selectOnboardingErrors,
  companyProfile: selectCompanyProfile
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
  backStepStart: (currentUser) => dispatch(backStepStart(currentUser)),
  updateOnboardingAttributesStart: ({ currentUser, newAttributes }) => dispatch(updateOnboardingAttributesStart({ currentUser, newAttributes })),
  updateOnboardingAttributesFailure: (error) => dispatch(updateOnboardingAttributesFailure(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSurvey);