import SurveysActionTypes from './surveys.types';
import ShiftActionTypes from '../shifts/shifts.types';
import UserActionTypes from '../user/user.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentSurveys: null,
  currentPrompts: null,
  currentResponses: null
}

const surveysReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    case ShiftActionTypes.DELETE_SHIFT_SUCCESS: {
      const prevSurveys = { ...state.currentSurveys };
      let newSurveys = prevSurveys;
      const deletedShift = action.payload.removedId;
      Object.keys(prevSurveys).forEach(surveyId => {
        if (prevSurveys[surveyId].shiftAssignment === deletedShift) {
          newSurveys[surveyId].shiftAssignment = "Unassigned";
        }
      });
      return {
        ...state,
        currentSurveys: newSurveys
      }
    }
    case SurveysActionTypes.DELETE_SURVEY_START:
    case SurveysActionTypes.ADD_SURVEY_START:
    case SurveysActionTypes.GET_SURVEYS_START:
    case SurveysActionTypes.UPDATE_SURVEY_START:
    case SurveysActionTypes.TOGGLE_SURVEY_START:
    case SurveysActionTypes.MANUAL_SURVEY_START:
    case SurveysActionTypes.GET_PROMPTS_START:
      return {
        ...state,
        loading: true
      }
    case SurveysActionTypes.UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      }
    case SurveysActionTypes.MANUAL_SURVEY_SUCCESS:
      return {
        ...state,
        currentSurveys: {
          ...state.currentSurveys,
          [action.payload.surveyId]: action.payload
        },
        loading: false,
        error: null
      }
    case SurveysActionTypes.ADD_SURVEY_SUCCESS:
      return {
        ...state,
        currentSurveys: {
          ...state.currentSurveys,
          [action.payload.newSurveyId]: action.payload.newSurveyData
        },
        loading: false,
        error: null
      }
    case SurveysActionTypes.DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        currentSurveys: action.payload.surveyData,
        loading: false,
        error: null
      }
    case SurveysActionTypes.GET_SURVEYS_SUCCESS:
      return {
        ...state,
        currentSurveys: action.payload.currentSurveys,
        loading: false,
        error: null
      }
    case SurveysActionTypes.GET_PROMPTS_SUCCESS:
      return {
        ...state,
        currentSurveys: action.payload.currentSurveys,
        currentPrompts: action.payload.currentPrompts,
        currentResponses: action.payload.currentResponses,
        loading: false,
        error: null
      }
    case SurveysActionTypes.TOGGLE_SURVEY_SUCCESS:
      return {
        ...state,
        currentSurveys: {
          ...state.currentSurveys,
          [action.payload.surveyId]: action.payload
        },
        loading: false,
        error: null
      }
    case SurveysActionTypes.UPDATE_SURVEY_FAILURE:
    case SurveysActionTypes.DELETE_SURVEY_FAILURE:
    case SurveysActionTypes.ADD_SURVEY_FAILURE:
    case SurveysActionTypes.GET_SURVEYS_FAILURE:
    case SurveysActionTypes.TOGGLE_SURVEY_FAILURE:
    case SurveysActionTypes.MANUAL_SURVEY_FAILURE:
    case SurveysActionTypes.GET_PROMPTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong. Refresh the page and try again, or contact support@SalusSMS.com",
          error: action.payload
        }
      }
    default:
      return state;
  }
}

export default surveysReducer;