import CompanyActionTypes from './company.types';
import EmployeesActionTypes from '../employees/employees.types';
import ShiftsActionTypes from '../shifts/shifts.types';
import UserActionTypes from '../user/user.types';

const INITIAL_STATE = {
  error: null,
  loading: false,
  currentCompany: null
}

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    case ShiftsActionTypes.DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          shifts: state.currentCompany.shifts.filter(shiftId => shiftId !== action.payload.removedId)
        }
      }
    case ShiftsActionTypes.ADD_SHIFT_SUCCESS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          shifts: [
            ...state.currentCompany.shifts,
            action.payload.newShiftId
          ]
        }
      }
    case EmployeesActionTypes.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          employees: state.currentCompany.employees.filter(employeeId => employeeId !== action.payload.removedId)
        }
      }
    case EmployeesActionTypes.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          employees: [
            ...state.currentCompany.employees,
            action.payload.newEmployeeId
          ]
        }
      }
    case CompanyActionTypes.GET_COMPANY_DATA_START:
      return {
        ...state,
        loading: true
      }
    case CompanyActionTypes.GET_COMPANY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          title: "Something went wrong.",
          error: action.payload
        }
      }
    case CompanyActionTypes.GET_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentCompany: {
          ...action.payload
        }
      }
    default:
      return state;
  }
}

export default companyReducer;