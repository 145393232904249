import OnboardingActionTypes from './onboarding.types';
import UserActionTypes from '../user/user.types';

const INITIAL_STATE = {
  loadingOnboardingDoc: true,
  onboardingStepNum: 0,
  error: null,
  loadingAddGoal: false,
  isAddGoalModalOpen: false,
  loadingOnboardingAttributes: false,
  categoryValues: {},
  goalToEdit: {},
  userGoals: {}
}

const onboardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    case OnboardingActionTypes.RESET_ONBOARDING_DATA:
      return {
        ...INITIAL_STATE
      }
    case OnboardingActionTypes.SET_GOAL_START:
      return {
        ...state,
        loadingAddGoal: true
      }
    case OnboardingActionTypes.SET_GOAL_FAILURE:
      return {
        ...state,
        loadingAddGoal: false
      }
    case OnboardingActionTypes.SET_GOAL_SUCCESS:
      return {
        ...state,
        loadingAddGoal: false,
        userGoals: {
          ...state.userGoals,
          [action.payload.goalId]: action.payload.goalData
        }
      }
    case OnboardingActionTypes.SET_GOAL_TO_EDIT:
      return {
        ...state,
        goalToEdit: action.payload
      }
    case OnboardingActionTypes.TOGGLE_ONBOARDING_GOAL_MODAL:
      return {
        ...state,
        isAddGoalModalOpen: action.payload
      }
    case OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryValues: action.payload,
        error: null
      }
    case OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_FAILURE:
      return {
        ...state,
        error: {
          title: "Could not set goal category values.",
          error: action.payload
        }
      }
    case OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_START:
      return {
        ...state,
        loadingOnboardingAttributes: true
      }
    case OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingOnboardingAttributes: false,
        error: null
      }
    case OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_FAILURE:
      return {
        ...state,
        loadingOnboardingAttributes: false,
        error: {
          title: "Something went wrong.",
          error: action.payload
        }
      }
    case OnboardingActionTypes.GET_ONBOARDING_DOC_FAILURE:
      return {
        ...state,
        // loadingOnboardingDoc: false,
        error: {
          title: "Could not retrieve onboarding document.",
          error: action.payload
        }
      };
    case OnboardingActionTypes.GET_ONBOARDING_DOC_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingOnboardingDoc: false,
        error: null
      };
    case OnboardingActionTypes.NEXT_STEP_START:
      return {
        ...state,
        onboardingStepNum: state.onboardingStepNum + 1
      };
    case OnboardingActionTypes.BACK_STEP_START:
      return {
        ...state,
        onboardingStepNum: state.onboardingStepNum - 1
      };
    case OnboardingActionTypes.UPDATE_USERNAME_FAILURE:
      return {
        ...state,
        error: {
          title: "Could not update username.",
          error: action.payload
        }
      };
    case OnboardingActionTypes.UPDATE_USERNAME_SUCCESS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}

export default onboardingReducer;