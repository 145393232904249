import { takeLatest, put, all, call } from 'redux-saga/effects';

import EmployeesActionTypes from './employees.types';

import { 
  addEmployeeSuccess, addEmployeeFailure,
  getEmployeesStart, getEmployeesSuccess, getEmployeesFailure,
  updateEmployeeSuccess, updateEmployeeFailure,
  deleteEmployeeSuccess, deleteEmployeeFailure
} from './employees.actions';

import { setCurrentView } from '../view/view.actions';

import {
  addEmployeeDocument, getCurrentEmployeeDocuments, updateEmployeeDocument, deleteEmployeeDocument
} from '../../firebase/firebase.utils';

export function* addEmployee({ payload: { currentCompany, newEmployeeData } }) {
  try {
    const newEmployeeId = yield addEmployeeDocument(currentCompany, newEmployeeData);
    yield put(addEmployeeSuccess({ newEmployeeId, newEmployeeData }));
    yield put(setCurrentView("EmployeeList"));
  } catch (error) {
    yield put(addEmployeeFailure(error));
  }
}

export function* onAddEmployeeStart() {
  yield takeLatest(EmployeesActionTypes.ADD_EMPLOYEE_START, addEmployee);
}

export function* getCurrentEmployees({ payload: { currentCompany } }) {
  try {
    const currentEmployees = yield getCurrentEmployeeDocuments(currentCompany);
    yield put(getEmployeesSuccess(currentEmployees));
  } catch (error) {
    yield put(getEmployeesFailure(error));
  }
}

export function* onGetCurrentEmployees() {
  yield takeLatest(EmployeesActionTypes.GET_EMPLOYEES_START, getCurrentEmployees);
}

export function* updateEmployee({ payload: { employeeId, employeeData, currentCompany } }) {
  try {
    yield updateEmployeeDocument(employeeId, employeeData);
    yield put(updateEmployeeSuccess({ employeeId, employeeData }));
    yield put(getEmployeesStart({ currentCompany }));
  } catch (error) {
    yield put(updateEmployeeFailure(error));
  }
}

export function* onUpdateEmployeeStart() {
  yield takeLatest(EmployeesActionTypes.UPDATE_EMPLOYEE_START, updateEmployee);
}

export function* deleteEmployee({ payload: { currentCompany, currentEmployees, employeeId } }) {
  try {
    yield deleteEmployeeDocument(currentCompany, employeeId);
    let newEmployeeData = { ...currentEmployees };
    delete newEmployeeData[employeeId];
    yield put(deleteEmployeeSuccess({ employeeData: newEmployeeData, removedId: employeeId }));
    yield put(setCurrentView("EmployeeList"));
  } catch (error) {
    yield put(deleteEmployeeFailure(error));
  }
}

export function* onDeleteEmployee() {
  yield takeLatest(EmployeesActionTypes.DELETE_EMPLOYEE_START, deleteEmployee);
}

export function* employeesSagas() {
  yield all([
    call(onAddEmployeeStart),
    call(onGetCurrentEmployees),
    call(onUpdateEmployeeStart),
    call(onDeleteEmployee)
  ]);
}