import { createSelector } from 'reselect';

const selectShifts = state => state.shifts;

export const selectCurrentShifts = createSelector(
  [selectShifts],
  (shifts) => shifts.currentShifts
);

export const selectShiftsErrors = createSelector(
  [selectShifts],
  (shifts) => shifts.error
);

export const selectShiftsLoading = createSelector(
  [selectShifts],
  (shifts) => shifts.loading
);