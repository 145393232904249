import { takeLatest, put, all, call } from 'redux-saga/effects';

import CompanyActionTypes from './company.types';

import { 
  getCompanyDataStart, getCompanyDataSuccess, getCompanyDataFailure
} from './company.actions';

import { setCurrentView } from '../view/view.actions';

import {
  getCompanyDocument 
} from '../../firebase/firebase.utils';

export function* getCompanyData({ payload: { companyId } }) {
  try {
    const companyData = yield getCompanyDocument(companyId);
    yield put(getCompanyDataSuccess({ companyId, ...companyData }));
  } catch (error) {
    yield put(getCompanyDataFailure(error));
  }
}

export function* onGetCompanyDataStart() {
  yield takeLatest(CompanyActionTypes.GET_COMPANY_DATA_START, getCompanyData);
}

export function* setCurrentCompany({ payload: { companyId } }) {
  try {
    yield put(getCompanyDataStart(companyId));
    // const newCompanyId = yield createInitialCompanyDocument(currentUser, companyAttributes);
    // yield put(createInitialCompanySuccess(newCompanyId));
    // yield put(setCurrentView("GettingStarted"));
  } catch (error) {
    // yield put(createInitialCompanyFailure(error));
    console.error(error);
  }
}

export function* onSetCurrentCompanyStart() {
  yield takeLatest(CompanyActionTypes.SET_CURRENT_COMPANY_START, setCurrentCompany);
}

export function* companySagas() {
  yield all([
    call(onSetCurrentCompanyStart),
    call(onGetCompanyDataStart)
  ]);
}