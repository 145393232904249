import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Form, Grid, Header, Message, Segment, Image } from 'semantic-ui-react';

import BrandColors from '../../utils/brandColors';
import './LoginPage.styles.css';
import { selectUserErrors, selectUserLoading } from '../../redux/user/user.selectors';
import { googleSignInStart, emailSignInStart, resetPasswordStart } from '../../redux/user/user.actions';
import TextLinkButton from '../../components/text-link-button/TextLinkButton.component';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

class LoginPage extends React.Component { 
  state = {
    email: '',
    password: '',
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { emailSignInStart } = this.props;
    const { email, password } = this.state;

    emailSignInStart(email, password);
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    // const { googleSignInStart } = this.props;
    const { userErrors, resetPasswordStart, userLoading } = this.props;
    const { email, password } = this.state;

    return (
      <div className="loginBackground">
        <div className="loginBackgroundOverlay">
          <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' padded>
            <Grid.Column style={{ maxWidth: 450 }}>

              <Link to="/">
                <Header as='h1'>
                  <Image src={"/logos/Salus_Logo_wide_w.png"} style={{ margin: 0, height: 'auto', width: 'auto', maxWidth: '250px' }} />
                </Header>
              </Link>
              
              <Segment.Group stacked>
                <Segment>
                  <Form size='large' onSubmit={this.handleSubmit}>
                    <p style={{fontWeight: 'bold'}}>Well hello there! <span style={{color: BrandColors.HEX_COLOR_PRIMARY}}>Welcome back.</span></p>
                    <Form.Input 
                      fluid 
                      icon='user' 
                      iconPosition='left' 
                      placeholder='E-mail address' 
                      name="email" 
                      value={email}
                      onChange={this.handleChange} 
                    />
                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Password'
                      type='password'
                      name="password" 
                      value={password}
                      onChange={this.handleChange}
                    />

                    <Button type="submit" color={BrandColors.SEMANTIC_COLOR_PRIMARY} fluid size='large' loading={!!userLoading} disabled={!!userLoading}>
                      Login
                    </Button>

                  </Form>

                  {/* <Divider horizontal>SIGN IN WITH GOOGLE</Divider> */}
                  {/* <Button type='button' onClick={googleSignInStart} className="signInWithGoogleButton" icon="google" content="Sign in with Google" labelPosition="left"/> */}

                </Segment>
                <Segment secondary>
                  Forgot your password? <TextLinkButton className="slightLeftMargin" onClick={() => resetPasswordStart(email)}>Reset Password</TextLinkButton>
                </Segment>
              </Segment.Group>
              
              <Message info>
                Don't have an account yet? <Link to="/signup" className="slightLeftMargin">Sign Up Here</Link>
              </Message>
              
              {
                userErrors ? (
                  <Message error>
                    <Message.Header>{userErrors.title}</Message.Header>
                    { renderCustomizedErrorMessage(userErrors.error.code, userErrors.error.message) }
                  </Message>
                ) : null
              }
              
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userErrors: selectUserErrors,
  userLoading: selectUserLoading
});

const mapDispatchToProps = dispatch => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  emailSignInStart: (email, password) => dispatch(emailSignInStart({email, password})),
  resetPasswordStart: (email) => dispatch(resetPasswordStart(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);