import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Message, Divider, Segment, Header, Button, Embed } from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectOnboardingStepNum } from '../../redux/onboarding/onboarding.selectors';

import { signOutStart } from '../../redux/user/user.actions';
import { nextStepStart, backStepStart } from '../../redux/onboarding/onboarding.actions';

class WaitingList extends React.Component {
  render() {
    const { signOutStart, stepNum, nextStepStart, backStepStart, currentUser } = this.props;

    let profileCompleted = false;
    if (stepNum) {
      if (stepNum > 1) {
        profileCompleted = true;
      }
    }

    return (
      <Container>
        <Divider hidden />
        <Segment.Group stacked>
          <Segment>
            <Header as="h1">Thanks for signing up!</Header>
          </Segment>
          <Segment>
            {
              profileCompleted ? (
                <Message success>
                  <Message.Header>You are up next on the waiting list.</Message.Header>
                  <Message.Content>Thanks for completing your profile. We'll be reaching out soon!</Message.Content>
                </Message>
              ) : (
                <Message info>
                  <Message.Header>You are now on the waiting list.</Message.Header>
                  <Message.Content>Please fill out your profile below to get early access.</Message.Content>
                </Message>
              )
            }
            <Embed 
              id="426866699"
              placeholder="/media/thumbnails/SalusSMS-Demo-Thumbnail.jpg"
              source="vimeo"
              active
              autoplay={false}
            />
          </Segment>
          <Segment secondary>
            <Button primary 
              content={profileCompleted ? "Profile Completed" : "Complete Your Profile for Early Access"} 
              icon={profileCompleted ? "check" : "right chevron"}
              disabled={!!profileCompleted}
              labelPosition="right" 
              onClick={
                profileCompleted ? (
                  () => backStepStart(currentUser)
                ) : (
                  () => nextStepStart(currentUser)
                )
              }
            />
            <Button content="Return to Homepage" icon="sign out" labelPosition="right" onClick={() => signOutStart()}/>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  stepNum: selectOnboardingStepNum,
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
  nextStepStart: (currentUser) => dispatch(nextStepStart(currentUser)),
  backStepStart: (currentUser) => dispatch(backStepStart(currentUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingList);