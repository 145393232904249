
import axios from 'axios';

let base_cloud_function_url = null;

// Read config file and switch cloud function URL accordingly
const BUILD_LEVEL = require('./.build-config.json').buildLevel;
if (BUILD_LEVEL === "dev") {
  base_cloud_function_url = "https://us-central1-salus-dev.cloudfunctions.net/";
} else if (BUILD_LEVEL === "stage") {
  base_cloud_function_url = "https://us-central1-salus-sms-stage-563fb.cloudfunctions.net/";
} else if (BUILD_LEVEL === "demo") {
  base_cloud_function_url = "https://us-central1-salus-demo.cloudfunctions.net/";
} else if (BUILD_LEVEL === "prod") {
  base_cloud_function_url = "https://us-central1-salus-sms.cloudfunctions.net/";
} else {
  console.error(`ATTENTION: No firebase cloud function config exists for ${BUILD_LEVEL}`);
}

// Build default access-control header
const headers = {
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
  }
};

export const cloudFunctions = axios.create({
  baseURL: base_cloud_function_url
}, headers);