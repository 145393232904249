import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Message, Divider, Segment, Header, Button, Embed, Dimmer, Form } from 'semantic-ui-react';

import { selectCurrentUser, selectAccessError, selectAccessLoading } from '../../redux/user/user.selectors';
import { signOutStart, unlockAccessStart, unlockAccessFailure } from '../../redux/user/user.actions';

class BetaPassCode extends React.Component {
  state = { active: true, passcode: '' };

  handlePassCodeSubmit = () => {
    const { passcode } = this.state;
    const { unlockAccessFailure, currentUser, unlockAccessStart } = this.props;
    if (!this.state.passcode) {
      return unlockAccessFailure({
        code: "custom/failed-access-code",
        message: "Please enter an access code"
      })
    } 
    unlockAccessStart({ currentUser, passCode: passcode });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { passcode } = this.state
    const { signOutStart, currentUser, accessError, accessLoading } = this.props;

    // TBD - Access token should be verified programmatically and securely
    const accessToken = currentUser.accessToken;
    const accessGrated = !!accessToken;

    return (
      <Dimmer active={!accessGrated} page style={{ overflow: 'scroll' }}>
        <Container>
          <Divider hidden />
          <Segment.Group stacked compact>
            <Segment>
              <Header as="h1">Thanks for signing up!</Header>
            </Segment>
            <Segment>
              <Message info compact>
                <Message.Header>You are up next on the waiting list.</Message.Header>
                <Message.Content>We'll be reaching out soon with an access code!</Message.Content>
                <Message.Content>Didn't hear from us yet? Contact us at <a href="mailto:hello@salussms.com">hello@SalusSMS.com</a></Message.Content>
              </Message>
              <Embed 
                id="426866699"
                placeholder="/media/thumbnails/SalusSMS-Demo-Thumbnail.jpg"
                source="vimeo"
              />
              <Header><Header.Subheader>Already got your access code?</Header.Subheader></Header>
              <Form size="large" onSubmit={this.handlePassCodeSubmit}>
                <Form.Input  
                  style={{ maxWidth: '250px' }}
                  icon='lock' 
                  iconPosition='left' 
                  placeholder='Enter Access Code' 
                  name="passcode" 
                  value={passcode}
                  onChange={this.handleChange} 
                />
                <Button primary type="submit" 
                  loading={!!accessLoading}
                  disabled={!!accessLoading}
                >Unlock Access</Button>
              </Form>
              {accessError && <Message error compact>
                <Message.Header>Incorrect Passcode.</Message.Header>
                <Message.Content>{accessError.message || "Please contact hello@SalusSMS.com to get your passcode."}</Message.Content>
              </Message>}
            </Segment>
            <Segment secondary>
              <Button content="Sign Out to Homepage" icon="sign out" labelPosition="right" onClick={() => signOutStart()}/>
            </Segment>
          </Segment.Group>
        </Container>
      </Dimmer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  accessError: selectAccessError,
  accessLoading: selectAccessLoading
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
  unlockAccessFailure: (error) => dispatch(unlockAccessFailure(error)),
  unlockAccessStart: ({ currentUser, passCode }) => dispatch(unlockAccessStart({ currentUser, passCode }))
});

export default connect(mapStateToProps, mapDispatchToProps)(BetaPassCode);