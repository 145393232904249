import React from 'react';
import { Container, Segment, Header, Divider, Button, Grid, Message, Form } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectUserErrors, selectUserLoading } from '../../redux/user/user.selectors';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { createInitialCompanyStart, createInitialCompanyFailure } from '../../redux/user/user.actions';
import { setCurrentView } from '../../redux/view/view.actions';

import BrandColors from '../../utils/brandColors';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

const WORKFORCE_SIZE_OPTIONS = [
  { key: '1', text: '0 - 50 employees', value: "50 or less" },
  { key: '2', text: '51 - 250 employees', value: "51 - 250" },
  { key: '3', text: '250 - 1000 employees', value: "250 - 1000" },
  { key: '4', text: 'More than 1000 employees', value: "1000+" },
];

class CreateInitialCompany extends React.Component {
  state = {
    companyName: '',
    companyCity: '',
    companyState: '', 
    workforceSize: ''
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentUser, createInitialCompanyStart, createInitialCompanyFailure } = this.props;
    const { companyName, companyCity, companyState, workforceSize } = this.state;
    
    if ( !companyName ) {
      createInitialCompanyFailure({
        code: "custom/failed-initial-company-creation",
        message: "Please fill out all required fields"
      })
      return;
    }

    createInitialCompanyStart({ currentUser, companyName, companyCity, companyState, workforceSize });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    const { setCurrentView, userErrors, userLoading } = this.props;
    const { companyName, companyCity, companyState, workforceSize } = this.state;

    return (
      <Container>
        <Divider hidden />

        <Header as="h1">
          <Header.Content>Setup Your Account</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>Create Your Company Profile</Header>
          </Segment>
          <Segment>

            <Grid>
              <Grid.Column>
                <Form size='large' onSubmit={this.handleSubmit} loading={!!userLoading}>
                  <p style={{fontWeight: 'bold', marginTop: 0 }}>Your profile will be added as the first <span style={{color: BrandColors.HEX_COLOR_PRIMARY}}>admin account</span> for your company.</p>
                  <Form.Input 
                    fluid 
                    required
                    icon='building'
                    iconPosition='left' 
                    placeholder='Your Company Name' 
                    name="companyName" 
                    value={companyName}
                    onChange={this.handleChange} 
                  />
                  <Form.Input 
                    fluid 
                    icon='map marker alternate'
                    iconPosition='left' 
                    placeholder='Company City (Optional)' 
                    name="companyCity" 
                    value={companyCity}
                    onChange={this.handleChange} 
                  />
                  <Form.Input 
                    fluid 
                    icon='map marker alternate'
                    iconPosition='left' 
                    placeholder='Company State (Optional)' 
                    name="companyState" 
                    value={companyState}
                    onChange={this.handleChange} 
                  />
                  <Form.Select 
                    fluid
                    placeholder='Approximate Company Workforce Size (Optional)' 
                    name="workforceSize" 
                    options={WORKFORCE_SIZE_OPTIONS}
                    value={workforceSize}
                    onChange={this.handleSelectChange} 
                  />
                </Form>

                {
                  userErrors ? (
                    <Message error>
                      <Message.Header>{userErrors.title}</Message.Header>
                      { renderCustomizedErrorMessage(userErrors.error.code, userErrors.error.message) }
                    </Message>
                  ) : null
                }

              </Grid.Column>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button content="Edit User Profile" labelPosition="left" icon="chevron left" 
              loading={!!userLoading} 
              disabled={!!userLoading} 
              onClick={() => setCurrentView("CompleteInitialUserProfile")}
            />
            <Button color="green" content="Create Profile" labelPosition="left" icon="check" 
              loading={!!userLoading} 
              disabled={!!userLoading} 
              onClick={this.handleSubmit}
            />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentUser: selectCurrentUser,
  userErrors: selectUserErrors,
  userLoading: selectUserLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  createInitialCompanyStart: (props) => dispatch(createInitialCompanyStart(props)),
  createInitialCompanyFailure: (props) => dispatch(createInitialCompanyFailure(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateInitialCompany);