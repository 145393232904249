import React from 'react';
import { Container, Segment, Header, Divider, Button, Grid, Message, Form } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectUserErrors, selectUserLoading } from '../../redux/user/user.selectors';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { createInitialUserProfileStart, createInitialUserProfileFailure } from '../../redux/user/user.actions';
import { setCurrentView } from '../../redux/view/view.actions';

import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

class CompleteInitialUserProfile extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    phoneNumber: ''
  }

  componentDidMount() {
    const { currentUser } = this.props;
    if (currentUser.firstName || currentUser.lastName || currentUser.phoneNumber) {
      this.setState({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        phoneNumber: currentUser.phoneNumber || "",
      })
    }
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentUser, createInitialUserProfileStart, createInitialUserProfileFailure } = this.props;
    const { firstName, lastName, phoneNumber } = this.state;

    // TBD - Validate phone number
    if ( !firstName || !lastName ) {
      createInitialUserProfileFailure({
        code: "custom/failed-initial-user-creation",
        message: "Please fill out all required fields"
      })
      return;
    }

    createInitialUserProfileStart({ currentUser, firstName, lastName, phoneNumber });
  }

  render() {
    const { currentUser, setCurrentView, userErrors, userLoading } = this.props;
    const { firstName, lastName, phoneNumber } = this.state;

    return (
      <Container>
        <Divider hidden />

        <Header as="h1">
          <Header.Content>Setup Your Account</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>Complete Your User Profile</Header>
          </Segment>
          <Segment>

            <Grid>
              <Grid.Column>
                <Form size='large' onSubmit={this.handleSubmit} loading={!!userLoading}>
                  <Form.Group widths="equal">
                    <Form.Input 
                      fluid 
                      required
                      icon='user'
                      iconPosition='left' 
                      placeholder='Your First Name' 
                      name="firstName" 
                      value={firstName}
                      onChange={this.handleChange} 
                    />
                    <Form.Input 
                      fluid 
                      required
                      placeholder='Your Last Name' 
                      name="lastName" 
                      value={lastName}
                      onChange={this.handleChange} 
                    />
                  </Form.Group>
                  <Form.Input 
                    fluid 
                    required
                    icon='mail'
                    iconPosition='left' 
                    placeholder='Your Email Address' 
                    name="email" 
                    value={currentUser.email}
                    disabled
                  />
                  <Form.Input 
                    fluid
                    icon='phone'
                    iconPosition='left' 
                    placeholder='Your Phone Number (Optional)' 
                    name="phoneNumber" 
                    value={phoneNumber}
                    onChange={this.handleChange} 
                  />
                </Form>

                {
                  userErrors ? (
                    <Message error>
                      <Message.Header>{userErrors.title}</Message.Header>
                      { renderCustomizedErrorMessage(userErrors.error.code, userErrors.error.message) }
                    </Message>
                  ) : null
                }

              </Grid.Column>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button color="green" content="Continue" labelPosition="right" icon="chevron right" 
              loading={!!userLoading} 
              disabled={!!userLoading} 
              onClick={this.handleSubmit}
            />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentUser: selectCurrentUser,
  userErrors: selectUserErrors,
  userLoading: selectUserLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  createInitialUserProfileStart: (props) => dispatch(createInitialUserProfileStart(props)),
  createInitialUserProfileFailure: (props) => dispatch(createInitialUserProfileFailure(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteInitialUserProfile);