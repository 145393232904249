import CompanyActionTypes from './company.types';

export const getCompanyDataStart = (companyId) => ({
  type: CompanyActionTypes.GET_COMPANY_DATA_START,
  payload: {
    companyId
  }
});

export const getCompanyDataSuccess = (companyData) => ({
  type: CompanyActionTypes.GET_COMPANY_DATA_SUCCESS,
  payload: companyData
});

export const getCompanyDataFailure = error => ({
  type: CompanyActionTypes.GET_COMPANY_DATA_FAILURE,
  payload: error
});

export const setCurrentCompany = companyId => ({
  type: CompanyActionTypes.SET_CURRENT_COMPANY_START,
  payload: {
    companyId
  }
});