import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import 'semantic-ui-css/semantic.min.css';

import { selectCurrentUser } from './redux/user/user.selectors';
import { checkUserSession } from './redux/user/user.actions';

import HomePage from '../src/pages/homepage/HomePage.component';
import LoginPage from '../src/pages/login/LoginPage.component';
import SignUpPage from '../src/pages/signup/SignUpPage.component';
import OnboardingPage from '../src/pages/onboarding/OnboardingPage.component';

import './App.css';

class App extends React.Component {

  componentDidMount() {
    const { checkUserSession } = this.props;
    checkUserSession();
  }

  render() {
    return (
      <div>
        {/* <Header/> */}
        <Switch>
          <Route exact path='/' render={
            () => this.props.currentUser ?
            (<OnboardingPage />) :
            (<HomePage />)
          } />
          {/* <Route exact path='/' component={HomePage} /> */}
          {/* <Route path='/shop' component={ShopPage} /> */}
          {/* <Route path='/checkout' component={CheckoutPage} /> */}
          <Route exact path='/login' 
            render={
              () => this.props.currentUser ? 
              (<Redirect to='/' />) 
              : 
              (<LoginPage />)} 
          />
          <Route exact path='/signup' 
            render={
              () => this.props.currentUser ? 
              (<Redirect to='/' />) 
              : 
              (<SignUpPage />)} 
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
