const renderCustomizedErrorMessage = (errorCode, errorMsg) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "Either the email you entered isn't right or no account was found matching this email/password combination.";
    case "auth/user-not-found":
      return "Looks like there's no user with that email address. If it existed, it doesn't anymore. Go ahead and sign up!";
    default:
      return errorMsg;
  }
};

export default renderCustomizedErrorMessage;