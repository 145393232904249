const SurveysActionTypes = {
  ADD_SURVEY_START: 'ADD_SURVEY_START',
  ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS',
  ADD_SURVEY_FAILURE: 'ADD_SURVEY_FAILURE',

  GET_SURVEYS_START: 'GET_SURVEYS_START',
  GET_SURVEYS_SUCCESS: 'GET_SURVEYS_SUCCESS',
  GET_SURVEYS_FAILURE: 'GET_SURVEYS_FAILURE',

  UPDATE_SURVEY_START: 'UPDATE_SURVEY_START',
  UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
  UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

  DELETE_SURVEY_START: 'DELETE_SURVEY_START',
  DELETE_SURVEY_SUCCESS: 'DELETE_SURVEY_SUCCESS',
  DELETE_SURVEY_FAILURE: 'DELETE_SURVEY_FAILURE',

  TOGGLE_SURVEY_START: 'TOGGLE_SURVEY_START',
  TOGGLE_SURVEY_SUCCESS: 'TOGGLE_SURVEY_SUCCESS',
  TOGGLE_SURVEY_FAILURE: 'TOGGLE_SURVEY_FAILURE',

  MANUAL_SURVEY_START: 'MANUAL_SURVEY_START',
  MANUAL_SURVEY_SUCCESS: 'MANUAL_SURVEY_SUCCESS',
  MANUAL_SURVEY_FAILURE: 'MANUAL_SURVEY_FAILURE',

  GET_PROMPTS_START: 'GET_PROMPTS_START',
  GET_PROMPTS_SUCCESS: 'GET_PROMPTS_SUCCESS',
  GET_PROMPTS_FAILURE: 'GET_PROMPTS_FAILURE',
};

export default SurveysActionTypes;