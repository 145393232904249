import React from 'react';
import { Header, Segment, Divider, Container } from 'semantic-ui-react';
import TextLinkButton from '../text-link-button/TextLinkButton.component';

import { connect } from 'react-redux';
import { setCurrentView } from '../../redux/view/view.actions';

class GettingStarted extends React.Component {
  render() {
    const { setCurrentView } = this.props;

    return (
      <Container>
        <Header as="h1">Welcome to SalusSMS!</Header>
        <Segment.Group>
          <Segment>
            <Header>How to Get Started</Header>
          </Segment>
          <Segment>
            <Header>Step 1 - Add employees</Header>
            <p>Add employees by using the form on the <TextLinkButton onClick={() => setCurrentView("EmployeeList")}>Employees</TextLinkButton> tab.</p>
            <p>Alternatively, you can email the list as a CSV file to <a href="mailto:hello@SalusSMS.com">Hello@SalusSMS.com</a>.</p>
            <p>If you do send a file, make sure to include a name, phone number, manager, and shift designation for each employee on the list.</p>
            <Header>Step 2 - Create work shifts</Header>
            <p>Create as many work shifts as you need on the <TextLinkButton onClick={() => setCurrentView("ShiftList")}>Shifts</TextLinkButton> tab, and set the shift start times accordingly.</p>
            <p>Default notifications are 60 minutes prior to shift start, and this can be updated as well.</p>
            <p>If you wish for the shift start times to be setup automatically by Salus, include the shift start times in the CSV file for Step 1.</p>
            <Header>Step 3 - Assign employees to shifts</Header>
            <p>Assign each employee to their proper shift on the <TextLinkButton onClick={() => setCurrentView("ShiftList")}>Shifts</TextLinkButton> tab. You can also include this in your CSV file from Step 1.</p>
            <Header>Step 4 - Enable shift surveys</Header>
            <p>Designate which surveys should be sent to which shifts on the <TextLinkButton onClick={() => setCurrentView("SurveyList")}>Surveys</TextLinkButton> tab.</p>
            <Header>Step 5 - Receive and manage responses</Header>
            <p>Response data can be viewed through the <TextLinkButton onClick={() => setCurrentView("ResponseList")}>Responses</TextLinkButton> tab.</p>
            <p>Results can also be automatically emailed to employee supervisors at the beginning of the shift.</p>
            <Divider hidden />
            <Header>That's it!</Header>
            <p>Any questions? Feel free to reach out to us at <a href="mailto:hello@SalusSMS.com">Hello@SalusSMS.com</a></p>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData))
});

export default connect(null, mapDispatchToProps)(GettingStarted);