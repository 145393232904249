const OnbaordingActionTypes = {
  NEXT_STEP_START: 'NEXT_STEP_START',
  NEXT_STEP_FAILURE: 'NEXT_STEP_FAILURE',
  NEXT_STEP_SUCCESS: 'NEXT_STEP_SUCCESS',

  BACK_STEP_START: 'BACK_STEP_START',
  BACK_STEP_FAILURE: 'BACK_STEP_FAILURE',
  BACK_STEP_SUCCESS: 'BACK_STEP_SUCCESS',

  UPDATE_USERNAME_START: 'UPDATE_USERNAME_START',
  UPDATE_USERNAME_FAILURE: 'UPDATE_USERNAME_FAILURE',
  UPDATE_USERNAME_SUCCESS: 'UPDATE_USERNAME_SUCCESS',

  GET_ONBOARDING_DOC_START: 'GET_ONBOARDING_DOC_START',
  GET_ONBOARDING_DOC_FAILURE: 'GET_ONBOARDING_DOC_FAILURE',
  GET_ONBOARDING_DOC_SUCCESS: 'GET_ONBOARDING_DOC_SUCCESS',

  UPDATE_ONBOARDING_ATTRIBUTES_START: 'UPDATE_ONBOARDING_ATTRIBUTES_START',
  UPDATE_ONBOARDING_ATTRIBUTES_FAILURE: 'UPDATE_ONBOARDING_ATTRIBUTES_FAILURE',
  UPDATE_ONBOARDING_ATTRIBUTES_SUCCESS: 'UPDATE_ONBOARDING_ATTRIBUTES_SUCCESS',

  SET_ONBOARDING_CATEGORIES_START: 'SET_ONBOARDING_CATEGORIES_START',
  SET_ONBOARDING_CATEGORIES_FAILURE: 'SET_ONBOARDING_CATEGORIES_FAILURE',
  SET_ONBOARDING_CATEGORIES_SUCCESS: 'SET_ONBOARDING_CATEGORIES_SUCCESS',

  TOGGLE_ONBOARDING_GOAL_MODAL: 'TOGGLE_ONBOARDING_GOAL_MODAL',

  SET_GOAL_TO_EDIT: 'SET_GOAL_TO_EDIT',

  SET_GOAL_START: 'SET_GOAL_START',
  SET_GOAL_FAILURE: 'SET_GOAL_FAILURE',
  SET_GOAL_SUCCESS: 'SET_GOAL_SUCCESS',

  RESET_ONBOARDING_DATA: 'RESET_ONBOARDING_DATA'
};

export default OnbaordingActionTypes;