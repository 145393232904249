import React from 'react';
import { Container, Segment, Header, Button, List, Label, Placeholder, Icon, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getEmployeesStart } from '../../redux/employees/employees.actions';
import { selectCurrentEmployees, selectEmployeesLoading } from '../../redux/employees/employees.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';

class EmployeeList extends React.Component {
  componentDidMount() {
    const { currentCompany, getEmployeesStart, getShiftsStart } = this.props;
    getEmployeesStart({ currentCompany });
    getShiftsStart({ currentCompany });
  }

  renderEmployeeSublist = (employeeList) => {
    const { setCurrentView } = this.props; 
    const employeeIds = Object.keys(employeeList);
    return (
      <List divided verticalAlign='middle' animated size="large">
        { employeeIds.map(employeeId => (
          <List.Item key={employeeId}>
            <List.Content floated='right'>
              <Button onClick={() => setCurrentView("EmployeeEdit", { employeeId })}>Manage</Button>
            </List.Content>
            <List.Icon name="user circle" size="large"/>
            <List.Content>
              {employeeList[employeeId].employeeName}
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  renderEmployeeList = () => {
    const { currentEmployees, currentShifts, shiftsLoading } = this.props;
    if (!currentEmployees) {
      if (!!shiftsLoading) return null;
      return <Message>Looks like you haven't added any employees yet. Add some using the button below!</Message>;
    } else {

      // Split into Assigned and Unassigned Employees by assigned shift Id
      let employeesByShift = {};
      const employeeIds = Object.keys(currentEmployees);
      if (!employeeIds.length) return <Message>Looks like you haven't added any employees yet. Add some using the button below!</Message>;
      employeeIds.forEach(employeeId => {
        const employee = currentEmployees[employeeId];
        employeesByShift[employee.shiftAssignment] = { 
          ...employeesByShift[employee.shiftAssignment],
          [employeeId]: employee 
        };
      });
      
      const unassignedEmployees = employeesByShift["Unassigned"] || {};
      const assignedShifts = Object.keys(employeesByShift).filter(shift => shift !== "Unassigned");
      
      return (
      <React.Fragment>
        {assignedShifts.length ? (
          <React.Fragment>
          <Header>Assigned Employees</Header>
          { assignedShifts.map(shiftId => (
            <React.Fragment key={shiftId}>
              { !currentShifts || !!shiftsLoading ? (
                <Placeholder>
                  <Placeholder.Header>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                </Placeholder>
              ) : (
                <Header as="h2">
                  <Header.Subheader>
                    {currentShifts[shiftId].shiftName}
                    <Label size="mini" style={{ marginLeft: '6px' }} image basic>
                      <Icon name='stopwatch' />
                      Starts
                      <Label.Detail>{moment(currentShifts[shiftId].shiftStart,"HH:mm").tz(currentShifts[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                    </Label>
                    { currentShifts[shiftId].shiftSendAuto ? (
                      <Label size="mini" style={{ marginLeft: '6px' }} image basic>
                        <Icon name='send' />
                        Autosend
                        <Label.Detail>{moment(currentShifts[shiftId].shiftStart,"HH:mm").tz(currentShifts[shiftId].shiftTimezone).subtract(+currentShifts[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                      </Label>
                    ) : null }
                  </Header.Subheader>
                </Header>
              ) }
            {this.renderEmployeeSublist(employeesByShift[shiftId])}
            </React.Fragment>
          )) }
          </React.Fragment>
        ) : null}
        {Object.keys(unassignedEmployees).length ? (
          <React.Fragment>
          <Header>Unassigned Employees</Header>
          {this.renderEmployeeSublist(unassignedEmployees)}
          </React.Fragment>
        ) : null}
      </React.Fragment>
      );
    }
  }

  render() {
    const { setCurrentView, employeesLoading } = this.props;

    return (
      <Container>
        {/* <Divider hidden />

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link >Back</Breadcrumb.Section>
        </Breadcrumb> */}

        <Header as="h1">
          <Header.Content>Manage Employees</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>All Employees by Shift</Header.Content>
            </Header>
          </Segment>

          <Segment loading={employeesLoading}>
            {this.renderEmployeeList()}
          </Segment>
          <Segment secondary>
            <Button color="blue" content="Add New Employee" labelPosition="left" icon="add" onClick={() => setCurrentView("EmployeeCreate")}/>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentEmployees: selectCurrentEmployees,
  employeesLoading: selectEmployeesLoading,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getEmployeesStart: ({ currentCompany }) => dispatch(getEmployeesStart({ currentCompany })),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);