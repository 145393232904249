import { createSelector } from 'reselect';

const selectEmployees = state => state.employees;

export const selectCurrentEmployees = createSelector(
  [selectEmployees],
  (employees) => employees.currentEmployees
);

export const selectEmployeesErrors = createSelector(
  [selectEmployees],
  (employees) => employees.error
);

export const selectEmployeesLoading = createSelector(
  [selectEmployees],
  (employees) => employees.loading
);