const EmployeesActionTypes = {
  ADD_EMPLOYEE_START: 'ADD_EMPLOYEE_START',
  ADD_EMPLOYEE_SUCCESS: 'ADD_EMPLOYEE_SUCCESS',
  ADD_EMPLOYEE_FAILURE: 'ADD_EMPLOYEE_FAILURE',

  GET_EMPLOYEES_START: 'GET_EMPLOYEES_START',
  GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
  GET_EMPLOYEES_FAILURE: 'GET_EMPLOYEES_FAILURE',

  UPDATE_EMPLOYEE_START: 'UPDATE_EMPLOYEE_START',
  UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_FAILURE: 'UPDATE_EMPLOYEE_FAILURE',

  DELETE_EMPLOYEE_START: 'DELETE_EMPLOYEE_START',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE'
};

export default EmployeesActionTypes;