import OnboardingActionTypes from './onboarding.types';

// Retrieve onboarding document

export const getOnboardingDocStart = (currentUser) => ({
  type: OnboardingActionTypes.GET_ONBOARDING_DOC_START,
  payload: { currentUser }
});

export const getOnboardingDocFailure = (error) => ({
  type: OnboardingActionTypes.GET_ONBOARDING_DOC_FAILURE,
  payload: error
});

export const getOnboardingDocSuccess = (document) => ({
  type: OnboardingActionTypes.GET_ONBOARDING_DOC_SUCCESS,
  payload: document
});

// Advance to the next onboarding step

export const nextStepStart = (currentUser) => ({
  type: OnboardingActionTypes.NEXT_STEP_START,
  payload: { currentUser }
});

export const nextStepFailure = (error) => ({
  type: OnboardingActionTypes.NEXT_STEP_FAILURE,
  payload: error
});

export const nextStepSuccess = () => ({
  type: OnboardingActionTypes.NEXT_STEP_SUCCESS
});

// Return to the previous onboarding step

export const backStepStart = (currentUser) => ({
  type: OnboardingActionTypes.BACK_STEP_START,
  payload: { currentUser }
});

export const backStepFailure = (error) => ({
  type: OnboardingActionTypes.BACK_STEP_FAILURE,
  payload: error
});

export const backStepSuccess = () => ({
  type: OnboardingActionTypes.BACK_STEP_SUCCESS
});

// Update username

export const updateUsernameStart = ({ currentUser, newUsername }) => ({
  type: OnboardingActionTypes.UPDATE_USERNAME_START,
  payload: {
    currentUser,
    newUsername
  }
});

export const updateUsernameFailure = (error) => ({
  type: OnboardingActionTypes.UPDATE_USERNAME_FAILURE,
  payload: error
});

export const updateUsernameSuccess = ({ displayName }) => ({
  type: OnboardingActionTypes.UPDATE_USERNAME_SUCCESS,
  payload: displayName
});

// Update onboarding document attributes

export const updateOnboardingAttributesStart = ({ currentUser, newAttributes }) => ({
  type: OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_START,
  payload: {
    currentUser,
    newAttributes
  }
});

export const updateOnboardingAttributesFailure = (error) => ({
  type: OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_FAILURE,
  payload: error
});

export const updateOnboardingAttributesSuccess = (newAttributes) => ({
  type: OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_SUCCESS,
  payload: newAttributes
});

// Update onboarding goal categories

export const setOnboardingGoalCategoriesStart = ({ currentUser, categoryValues }) => ({
  type: OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_START,
  payload: {
    currentUser,
    categoryValues
  }
});

export const setOnboardingGoalCategoriesFailure = (error) => ({
  type: OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_FAILURE,
  payload: error
});

export const setOnboardingGoalCategoriesSuccess = (categoryValues) => ({
  type: OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_SUCCESS,
  payload: categoryValues
});

// Toggle onboarding add goal modal

export const toggleOnboardingAddGoalModal = (doOpen) => ({
  type: OnboardingActionTypes.TOGGLE_ONBOARDING_GOAL_MODAL,
  payload: doOpen
});

// Set current goal to edit

export const setGoalToEdit = ({ goalCategory, goalId }) => ({
  type: OnboardingActionTypes.SET_GOAL_TO_EDIT,
  payload: {
    goalCategory,
    goalId
  }
});

// Set / Add / Edit goal

export const setGoalStart = ({ currentUser, goalId, goalCategory, goalText, goalDeadline }) => ({
  type: OnboardingActionTypes.SET_GOAL_START,
  payload: {
    currentUser, 
    goalId, 
    goalCategory, 
    goalText, 
    goalDeadline
  }
});

export const setGoalFailure = (error) => ({
  type: OnboardingActionTypes.SET_GOAL_FAILURE,
  payload: error
});

export const setGoalSuccess = (goalId, newGoal) => ({
  type: OnboardingActionTypes.SET_GOAL_SUCCESS,
  payload: {
    goalId,
    goalData: newGoal
  }
});

// Reset onboarding data
export const resetOnboardingData = () => ({
  type: OnboardingActionTypes.RESET_ONBOARDING_DATA
});