import { createSelector } from 'reselect';

const selectSurveys = state => state.surveys;

export const selectCurrentSurveys = createSelector(
  [selectSurveys],
  (surveys) => surveys.currentSurveys
);

export const selectCurrentPrompts = createSelector(
  [selectSurveys],
  (surveys) => surveys.currentPrompts
);

export const selectCurrentResponses = createSelector(
  [selectSurveys],
  (surveys) => surveys.currentResponses
);

export const selectSurveysErrors = createSelector(
  [selectSurveys],
  (surveys) => surveys.error
);

export const selectSurveysLoading = createSelector(
  [selectSurveys],
  (surveys) => surveys.loading
);