import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectUserErrors = createSelector(
  [selectUser],
  (user) => user.error
);

export const selectUserLoading = createSelector(
  [selectUser],
  (user) => user.loading
);

export const selectAccessError = createSelector(
  [selectUser],
  (user) => user.accessError
);

export const selectAccessLoading = createSelector(
  [selectUser],
  (user) => user.accessLoading
);