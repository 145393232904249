const CompanyActionTypes = {
  SET_CURRENT_COMPANY_START: 'SET_CURRENT_COMPANY_START',
  SET_CURRENT_COMPANY_SUCCESS: 'SET_CURRENT_COMPANY_SUCCESS',
  SET_CURRENT_COMPANY_FAILURE: 'SET_CURRENT_COMPANY_FAILURE',

  GET_COMPANY_DATA_START: 'GET_COMPANY_DATA_START',
  GET_COMPANY_DATA_SUCCESS: 'GET_COMPANY_DATA_SUCCESS',
  GET_COMPANY_DATA_FAILURE: 'GET_COMPANY_DATA_FAILURE',
};

export default CompanyActionTypes;