import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import onboardingReducer from './onboarding/onboarding.reducer';
import viewReducer from './view/view.reducer';
import companyReducer from './company/company.reducer';
import employeesReducer from './employees/employees.reducer';
import shiftsReducer from './shifts/shifts.reducer';
import surveysReducer from './surveys/surveys.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
  // whitelist: ['cart'] // user storage is already handled with firebase auth
}

const rootReducer = combineReducers({
  user: userReducer,
  onboarding: onboardingReducer,
  view: viewReducer,
  company: companyReducer,
  employees: employeesReducer,
  shifts: shiftsReducer,
  surveys: surveysReducer
});

export default persistReducer(persistConfig, rootReducer);