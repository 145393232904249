import { takeLatest, put, all, call } from 'redux-saga/effects';

import OnboardingActionTypes from './onboarding.types';
import UserActionTypes from '../user/user.types';

import { 
  updateUsernameFailure,
  updateUsernameSuccess,

  nextStepStart,
  nextStepFailure,
  nextStepSuccess,

  getOnboardingDocFailure,
  getOnboardingDocSuccess,

  setOnboardingGoalCategoriesFailure,
  setOnboardingGoalCategoriesSuccess,

  setGoalSuccess,
  setGoalFailure,

  toggleOnboardingAddGoalModal,
  backStepSuccess,
  backStepFailure,

  updateOnboardingAttributesSuccess,
  updateOnboardingAttributesFailure,

  resetOnboardingData
} from './onboarding.actions';

import { 
  updateUserAttributes, updateUserOnboardingStep, 
  getUserOnboardingDocument, updateOnboardingCategoryValues,
  setUserGoal, updateUserOnboardingAttributes
} from '../../firebase/firebase.utils';

// Retrieve onboarding document

export function* getOnboardingDoc({ payload: { currentUser } }) {
  try {
    const onboardingDoc = yield call(getUserOnboardingDocument, currentUser);
    yield put(getOnboardingDocSuccess(onboardingDoc));
  } catch (error) {
    yield put(getOnboardingDocFailure(error));
  }
}

export function* onGetOnboardingDocStart() {
  yield takeLatest(OnboardingActionTypes.GET_ONBOARDING_DOC_START, getOnboardingDoc);
}

// Advance to next onboarding step

export function* advanceToNextStep({ payload: { currentUser } }) {
  try {
    yield call(updateUserOnboardingStep, currentUser);
    yield put(nextStepSuccess());
  } catch (error) {
    yield put(nextStepFailure(error));
  }
}

export function* onNextStepStart() {
  yield takeLatest(OnboardingActionTypes.NEXT_STEP_START, advanceToNextStep);
}

// Return to previous onboarding step

export function* returnToPreviousStep({ payload: { currentUser } }) {
  try {
    yield call(updateUserOnboardingStep, currentUser, -1);
    yield put(backStepSuccess());
  } catch (error) {
    yield put(backStepFailure(error));
  }
}

export function* onBackStepStart() {
  yield takeLatest(OnboardingActionTypes.BACK_STEP_START, returnToPreviousStep);
}

// Update username

export function* updateUsername({ payload: { currentUser, newUsername } }) {
  try {
    const updateObj = { displayName: newUsername };
    yield call(updateUserAttributes, currentUser, updateObj);
    yield put(updateUsernameSuccess(updateObj));
    yield put(nextStepStart(currentUser));
  } catch (error) {
    yield put(updateUsernameFailure(error));
  }
}

export function* onUpdateUsernameStart() {
  yield takeLatest(OnboardingActionTypes.UPDATE_USERNAME_START, updateUsername);
}

// Update user onboarding document attributes

export function* updateOnboardingAttributes({ payload: { currentUser, newAttributes } }) {
  try {
    // console.log("res: ",res);

    // const { currentUser, newAttributes } = res;

    // { payload: { currentUser, newAttributes } }


    yield call(updateUserOnboardingAttributes, currentUser, newAttributes);
    yield put(updateOnboardingAttributesSuccess(newAttributes));
    yield put(nextStepStart(currentUser));
  } catch (error) {
    yield put(updateOnboardingAttributesFailure(error));
  }
}

export function* onUpdateOnboardingAttributesStart() {
  yield takeLatest(OnboardingActionTypes.UPDATE_ONBOARDING_ATTRIBUTES_START, updateOnboardingAttributes);
}


// Update onbaording goal category values

export function* setOnboardingGoalCategories({ payload: { currentUser, categoryValues } }) {
  try {
    yield call(updateOnboardingCategoryValues, currentUser, categoryValues);
    yield put(setOnboardingGoalCategoriesSuccess(categoryValues));
    yield put(nextStepStart(currentUser));
  } catch (error) {
    yield put(setOnboardingGoalCategoriesFailure(error));
  }
}

export function* onSetOnboardingGoalCategoriesStart() {
  yield takeLatest(OnboardingActionTypes.SET_ONBOARDING_CATEGORIES_START, setOnboardingGoalCategories);
}

// Set / Edit goal

export function* onSetGoal({ 
  payload: { 
    currentUser, 
    goalId, 
    goalCategory, 
    goalText, 
    goalDeadline 
  } 
}) {
  try {
    const goalToSet = { 
      goalCategory, 
      goalText, 
      goalDeadline 
    };
    const goalRef = yield call(setUserGoal, currentUser, goalId, goalToSet);
    const goalDoc = yield goalRef.get();
    const goalDocData = yield goalDoc.data();
    yield put(setGoalSuccess(goalRef.id, goalDocData));
    yield put(toggleOnboardingAddGoalModal(false));
  } catch (error) {
    yield put(setGoalFailure(error));
  }
}

export function* onSetGoalStart() {
  yield takeLatest(OnboardingActionTypes.SET_GOAL_START, onSetGoal);
}

// On user signout

export function* onUserSignout() {
  yield put(resetOnboardingData());
}

export function* onUserSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, onUserSignout);
}

// Combine exports

export function* onboardingSagas() {
  yield all([ 
    call(onGetOnboardingDocStart),
    call(onUpdateUsernameStart),
    call(onNextStepStart),
    call(onBackStepStart),
    call(onSetOnboardingGoalCategoriesStart),
    call(onUpdateOnboardingAttributesStart),
    call(onSetGoalStart),
    call(onUserSignOutStart)
  ]);
}