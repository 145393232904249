import React from 'react';
import { Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, Dropdown, Icon, Checkbox, Message, Form } from 'semantic-ui-react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectEmployeesErrors, selectEmployeesLoading } from '../../redux/employees/employees.selectors';
import { addEmployeeStart, addEmployeeFailure } from '../../redux/employees/employees.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';

import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';
import TextLinkButton from '../text-link-button/TextLinkButton.component';

class EmployeeCreate extends React.Component {
  state = {
    employeeName: '',
    phoneNumber: '',
    email: '',
    shiftAssignment: ''
  }

  componentDidMount() {
    const { getShiftsStart, currentCompany } = this.props;
    getShiftsStart({ currentCompany });
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentCompany, addEmployeeStart, addEmployeeFailure } = this.props;
    const { employeeName, phoneNumber, email, shiftAssignment } = this.state;
    
    if ( !employeeName || !phoneNumber ) {
      addEmployeeFailure({
        code: "custom/failed-new-employee-creation",
        message: "Please fill out all required fields"
      })
      return;
    }

    addEmployeeStart({ 
      currentCompany, 
      newEmployeeData: {
        employeeName, 
        phoneNumber, 
        email, 
        shiftAssignment: shiftAssignment || "Unassigned" 
      }
    });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  render() {
    const { employeeName, phoneNumber, email, shiftAssignment } = this.state;
    const { 
      setCurrentView, employeesErrors, employeesLoading,
      shiftsLoading, currentShifts
    } = this.props;

    return (
      <Container>

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("EmployeeList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>Add New Employee</Header.Content>
        </Header>

        <Segment.Group>
          {/* TBD - Add CSV uploader */}
          {/* <Message info attached='top'>
            <Icon name='question' />
            Adding a bunch of employees at once? Use our bulk uploader!
            <Button compact color="teal" content="Upload .CSV File" labelPosition="right" icon="excel file outline" size="mini" floated="right"/>
          </Message> */}
          <Segment>

            <Grid>
              <Grid.Column>

                <Form size="large" onSubmit={this.handleSubmit} loading={!!employeesLoading}>
                  <Header>Employee Information</Header>
                  <Form.Input
                    fluid
                    autoComplete="off"
                    icon='user' 
                    iconPosition='left' 
                    placeholder='Employee Name' 
                    required
                    name="employeeName" 
                    value={employeeName}
                    onChange={this.handleChange} 
                  />
                  <Form.Field>
                    <PhoneInput 
                      required
                      autoComplete="off"
                      defaultCountry="US"
                      name="phoneNumber"
                      value={phoneNumber}
                      placeholder='Cell Phone Number' 
                      onChange={(newValue) => this.setState({ phoneNumber: newValue })} 
                    />
                  </Form.Field>

                  <Form.Input 
                    fluid
                    autoComplete="off"
                    icon='mail' 
                    iconPosition='left' 
                    placeholder='Email Address (Optional)' 
                    name="email" 
                    value={email}
                    onChange={this.handleChange} 
                  />

                  <Dropdown
                    search
                    selection
                    fluid
                    loading={!!shiftsLoading}
                    autoComplete="off"
                    name="shiftAssignment"
                    placeholder="Shift Assignment (Optional)"
                    header='Assign Shift'
                    options={
                      !currentShifts ? [{
                        key: "Unassigned",
                        value: "Unassigned",
                        text: "Unassigned"
                      }] : (
                        [ 
                          {
                            key: "Unassigned",
                            value: "Unassigned",
                            text: "Unassigned"
                          },
                          ...Object.keys(currentShifts).map(shiftId => ({
                            key: currentShifts[shiftId].shiftName,
                            value: shiftId,
                            text: currentShifts[shiftId].shiftName
                          }))
                        ]
                      )
                    }
                    value={shiftAssignment}
                    onChange={this.handleSelectChange} 
                  />

                  <div style={{ paddingTop: '0.5em' }}>
                    <i>You can always assign a shift later. Don't see the shift you're looking for?</i> <TextLinkButton onClick={() => setCurrentView("ShiftCreate")}>Create a new shift instead</TextLinkButton>
                  </div>
                  
                </Form>

                {
                  employeesErrors ? (
                    <Message error>
                      <Message.Header>Could not add employee.</Message.Header>
                      { renderCustomizedErrorMessage(employeesErrors.error.code, employeesErrors.error.message) }
                    </Message>
                  ) : null
                }

              </Grid.Column>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button color="green" content="Add Employee" labelPosition="left" icon="check" type="submit"
              loading={!!employeesLoading} 
              disabled={!!employeesLoading} 
              onClick={this.handleSubmit}
            />
            <Button color="red" content="Cancel" labelPosition="left" icon="cancel" onClick={() => setCurrentView("EmployeeList")} type="button" />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  employeesErrors: selectEmployeesErrors,
  employeesLoading: selectEmployeesLoading,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  addEmployeeStart: ({ currentCompany, newEmployeeData }) => dispatch(addEmployeeStart({ currentCompany, newEmployeeData })),
  addEmployeeFailure: (error) => dispatch(addEmployeeFailure(error)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCreate);